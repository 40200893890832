import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@/plugins/vee-validate";
import "./assets/scss/global.scss";

Vue.config.productionTip = false;

import axiosPlugin from "./plugins/axios-plugin";

Vue.use(axiosPlugin);

import VuetifyDialog from "vuetify-dialog";
import "vuetify-dialog/dist/vuetify-dialog.css";

Vue.use(VuetifyDialog, {
  context: {
    vuetify,
  },
});

Vue.use(require("vue-moment"));

import * as filters from "./filters";

// Register all filters
Object.keys(filters).forEach((filterName) => {
  Vue.filter(filterName, filters[filterName]);
});

// import VueSocketIO from "vue-socket.io";

// Vue.use(
//   new VueSocketIO({
//     debug: false,
//     connection: process.env.VUE_APP_SOCKET_URL,
//   })
// );

import VueCurrencyFilter from "vue-currency-filter";

Vue.use(VueCurrencyFilter, [
  {
    name: "usd",
    symbol: "$",
    thousandsSeparator: ",",
    fractionCount: 2,
    fractionSeparator: ".",
    symbolPosition: "front",
    symbolSpacing: true,
    avoidEmptyDecimals: undefined,
  },
  {
    name: "bs",
    symbol: "Bs.",
    thousandsSeparator: ",",
    fractionCount: 2,
    fractionSeparator: ".",
    symbolPosition: "front",
    symbolSpacing: true,
    avoidEmptyDecimals: undefined,
  },
]);

import DatetimePicker from 'vuetify-datetime-picker';
Vue.use(DatetimePicker)

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
