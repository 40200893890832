import Vue from "vue";
import { ValidationProvider, ValidationObserver, extend, setInteractionMode } from "vee-validate";
import {
  required,
  email,
  length,
  min,
  min_value,
  max,
  max_value,
  size,
  confirmed,
  ext,
  numeric,
  alpha,
  alpha_dash,
  alpha_spaces,
  digits,
  regex,
  alpha_num,
} from "vee-validate/dist/rules";
import { localize } from "vee-validate";
import es from "vee-validate/dist/locale/es.json";
import en from "vee-validate/dist/locale/en.json";

// Add rules
extend("required", required);
extend("email", email);
extend("length", length);
extend("min_value", min_value);
extend("min", min);
extend("max_value", max_value);
extend("max", max);
extend("ext", ext);
extend("size", size);
extend("numeric", numeric);
extend("confirmed", confirmed);
extend("alpha", alpha);
extend("alpha_spaces", alpha_spaces);
extend("digits", digits);
extend("regex", regex);
extend("alpha_dash", alpha_dash);
extend("alpha_num", alpha_num);

localize("es", es);

setInteractionMode('lazy');

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
