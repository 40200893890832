export default [{
    path: "/airplanes",
    component: () => import(/* webpackChunkName: "user" */ "@/layouts/user/UserLayout.vue"),
    children: [
        {
            path: "",
            name: "airplanes",
            component: () => import( /* webpackChunkName: "airplanes" */ "@/views/airplanes/Airplanes.vue")
        },
        {
            path: "details",
            name: "airplaneDetails",
            component: () => import( /* webpackChunkName: "airplanesDetails" */ "@/views/airplanes/AirplaneDetails.vue")
        }

    ]
}]